import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { basicStyle, Col, Row, Button, antMedia } from '@sejinmind/piip-ui-components/src/v1'
import { withRouterContext, Link, setCookie } from '@sejinmind/piip-utils'

const EXPIRE_COOKIE_DATE = 180

const CookieConsentWrapper = styled.div`
	width: 100%;
	position: fixed;
	bottom: 0;
	left: 0;
	background: linear-gradient(0deg, #22262b, #22262b);

	box-shadow: 0px -2px 8px rgba(48, 85, 135, 0.2);
	transition: all 600ms cubic-bezier(0.86, 0, 0.07, 1);
	z-index: 9999;

	color: #ffffff;
	font-size: 14px;
	line-height: 148%;
	opacity: 0;
	transform: translateY(100%);
	transition: all 500ms ease-out;
	${(props) =>
		props.active &&
		`
		opacity: 0.9;
		transform: translateY(0%);
		transition-delay: 500ms;
	`}

	.ant-row {
		height: inherit;
	}

	.consent-body {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 16px 0;
		${antMedia.lessThan('lg')`
			flex-direction: column;
			align-items: start;
		`}

		.consent-description {
			display: inline-block;
			max-width: 800px;
			${antMedia.lessThan('xl')`
				max-width: 696px;
			`}
			${antMedia.lessThan('lg')`
				max-width: none;
			`}

			.consent-link {
				color: #ffffff;
				font-weight: bold;
				text-decoration: underline;
			}
		}

		.consent-btn-group {
			display: inline-block;
			${antMedia.lessThan('lg')`
				margin-top: 16px;
			`}
			button {
				color: #ffffff;
			}
			.ant-btn-ghost {
				margin-right: 16px;
				&:hover {
					color: #333941;
					background-color: #ffffff;
					border-color: #ffffff;
				}
			}
			.ant-btn-link > span {
				text-decoration: underline;
			}
		}
	}
`

const CookieConsentComponent = ({
	router,
	language,
	consentClosedName,
	allowCookieName,
	isConsentClosed,
	allowCookie,
	isPageError,
}) => {
	const { rowStyle, colStyle, gutter } = basicStyle
	const [currentLang, setLang] = useState(language)
	useEffect(() => {
		setLang(language)
	}, [language])
	const [isConsent, setIsConsent] = useState(isConsentClosed)
	const isConsentActive = currentLang === 'en' && !isConsent && !isPageError

	const handleAccept = (e) => {
		e.preventDefault()
		setCookie(consentClosedName, true, EXPIRE_COOKIE_DATE)
		setCookie(allowCookieName, true, EXPIRE_COOKIE_DATE)
		setIsConsent(true)
	}

	const handleReject = (e) => {
		e.preventDefault()
		setCookie(consentClosedName, true, EXPIRE_COOKIE_DATE)
		setCookie(allowCookieName, false, EXPIRE_COOKIE_DATE)
		setIsConsent(true)
	}

	return (
		<CookieConsentWrapper active={isConsentActive}>
			<Row style={rowStyle} gutter={gutter} type="flex" align="middle">
				<Col span={24} className="consent-body">
					<div className="consent-description">
						We use cookies to offer you a better experience, analyze site traffic, and
						serve targeted advertisements. By continuing to use this website, you
						consent to the use of cookies in accordance with our{' '}
						<Link
							href={{
								pathname: `/cookie-policy`,
								query: { language },
							}}
							as={`/${language}/cookie-policy`}>
							<a className="consent-link">Cookie Policy.</a>
						</Link>
					</div>
					<div className="consent-btn-group">
						<Button type="ghost" onClick={handleAccept}>
							Accept
						</Button>
						<Button type="link" onClick={handleReject}>
							Reject
						</Button>
					</div>
				</Col>
			</Row>
		</CookieConsentWrapper>
	)
}

const CookieConsent = withRouterContext(CookieConsentComponent)

export { CookieConsent }
