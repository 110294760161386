export default {
	en: {
		title: 'PI IP LAW',
		description: 'Expert-Driven Protections, Business-Driven Results',
		canonical: 'https://piip.co.kr',
		languageAlternates: [
			{ hrefLang: 'en', href: 'https://piip.co.kr/en' },
			{ hrefLang: 'ko', href: 'https://piip.co.kr/ko' },
		],
		openGraph: {
			type: 'website',
			locale: 'en_US',
			url: 'https://piip.co.kr',
			title: 'PI IP LAW',
			description: 'Expert-Driven Protections, Business-Driven Results',
			site_name: 'PI IP LAW',
			images: [
				{
					url: 'https://piip.co.kr/static/images/main_logo_en.png',
					width: 1200,
					height: 630,
					alt: 'PI IP LAW',
				},
			],
		},
	},
	ko: {
		title: '파이특허법률사무소',
		description: 'Expert-Driven Protections, Business-Driven Results',
		canonical: 'https://piip.co.kr/ko',
		languageAlternates: [
			{ hrefLang: 'en', href: 'https://piip.co.kr/en' },
			{ hrefLang: 'ko', href: 'https://piip.co.kr/ko' },
		],
		openGraph: {
			type: 'website',
			locale: 'ko_KR',
			url: 'https://piip.co.kr/ko',
			title: '파이특허법률사무소',
			description: 'Expert-Driven Protections, Business-Driven Results',
			site_name: '파이특허법률사무소',
			images: [
				{
					url: 'https://piip.co.kr/static/images/main_logo_kr.png',
					width: 1200,
					height: 630,
					alt: '파이특허법률사무소',
				},
			],
		},
	},
}
