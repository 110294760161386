import { useStaticRendering } from 'mobx-react'

const isServer = typeof window === 'undefined'
useStaticRendering(isServer)

class PiipFrontWebappStore {
	constructor(isServer, initialData = {}) {
		if (initialData.stores) {
			this.stores = { ...initialData.stores }
		} else {
			this.stores = {}
		}
	}

	getStores() {
		return Object.keys(this.stores).reduce((result, storeName, index) => {
			return { ...result, ...this.stores[storeName].getStores() }
		}, {})
	}

	register(name, store, options = {}) {
		this.stores[name] = store
	}

	unregister(store) {
		delete this.stores(store.name)
	}
}

let store = null
function initializeStore(initialData) {
	// Always make a new store if server, otherwise state is shared between requests
	if (isServer) {
		return new PiipFrontWebappStore(isServer, initialData)
	}
	if (store === null) {
		store = new PiipFrontWebappStore(isServer, initialData)
	}
	return store
}

export { initializeStore }
