import * as React from 'react'
import App from 'next/app'

import { themeDefault } from '@sejinmind/piip-ui-components/src/v1'
import '@sejinmind/piip-ui-components/src/v1/styles/global.css'

import {
	FrontApplication,
	FrontApplicationViewModel,
} from '@sejinmind/piip-webapp-modules/src/modules'

import { AppLocale } from '../languageProvider'

import { initializeStore } from '../store'
import {
	Fonts,
	getCookie,
	LinkProvider,
	RouterProvider,
	withApollo,
	LangSeoProvider,
	DefaultLangSeo,
} from '@sejinmind/piip-utils'
import { CookieConsent } from '../components/Consent'

import { API_ENDPOINT } from '@env'
import Router from 'next/router'
import Link from 'next/link'

import SEOConfig from '../next-seo.config'

const currentAppLocale = AppLocale['ko']
const GRAPHQL_ENDPOINT = `${API_ENDPOINT}/graphql`

const IS_CONSENT_CLOSED = 'cookie_consent_closed'
const ALLOW_COOKIE = 'allow_cookie'

class MyApp extends App {
	constructor(props) {
		super(props)

		const isServer = typeof window === 'undefined'
		this.mobxStore = isServer ? props.initialMobxState : initializeStore(props.initialMobxState)

		// 언어 및 전체 theme
		this.applicationModel = new FrontApplicationViewModel()
	}

	static async getInitialProps(appContext) {
		const { Component, ctx } = appContext

		const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {}

		const isConsentClosed = await getCookie(IS_CONSENT_CLOSED, ctx?.req)
		const allowCookie = await getCookie(ALLOW_COOKIE, ctx?.req)

		return { pageProps, isConsentClosed, allowCookie }
	}

	async componentDidMount() {
		const fontData = {
			NotoSansKR: {
				fontName: 'Noto Sans KR',
				url: '//fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700;900&display=swap',
			},
			EBGaramond: {
				fontName: 'EB Garamond',
				url: '//fonts.googleapis.com/css2?family=EB+Garamond:wght@500&display=swap',
			},
		}

		return await Fonts(fontData)
	}

	render() {
		const { Component, pageProps, apolloClient, isConsentClosed, allowCookie } = this.props
		const isPageError = pageProps?.statusCode >= 400 || pageProps?.pageProps?.statusCode >= 400
		const language =
			pageProps?.query?.language || pageProps?.router?.router?.query?.language || 'en'

		return (
			<React.Fragment>
				<LangSeoProvider seoConfig={SEOConfig}>
					<DefaultLangSeo language={language} />
					<FrontApplication
						theme={themeDefault}
						applicationModel={this.applicationModel}
						appLocale={'ko'}>
						<RouterProvider router={Router}>
							<LinkProvider link={Link}>
								<Component {...pageProps} />
								<CookieConsent
									consentClosedName={IS_CONSENT_CLOSED}
									allowCookieName={ALLOW_COOKIE}
									isConsentClosed={isConsentClosed}
									allowCookie={allowCookie}
									isPageError={isPageError}
									language={language}
								/>
							</LinkProvider>
						</RouterProvider>
					</FrontApplication>
				</LangSeoProvider>
			</React.Fragment>
		)
	}
}

export default withApollo(MyApp, { apiEndpoint: GRAPHQL_ENDPOINT, ssr: true })
