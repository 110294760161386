import Enlang from './entries/en-US'
import Kolang from './entries/ko-KR'
import { addLocaleData } from 'react-intl'

const AppLocale = {
	en: Enlang,
	ko: Kolang,
}
addLocaleData(AppLocale.en.data)
addLocaleData(AppLocale.ko.data)

export { AppLocale }
