import antdEn from 'antd/lib/locale/ko_KR'
import appLocaleData from 'react-intl/locale-data/ko'
import enMessages from '../locales/ko_KR.json'
// import { getKeys, getValues } from '../conversion';
// getValues(enMessages);

const KoLang = {
	messages: {
		...enMessages,
	},
	antd: antdEn,
	locale: 'ko-KR',
	data: appLocaleData,
}
export default KoLang
